<template>
  <router-view></router-view>
</template>

<script setup>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import { getToken } from '@/utils/utils'
import { useRouter, useRoute, onBeforeRouteUpdate } from 'vue-router'
import { loginPath } from '@/utils/consts'

const router = useRouter()
const store = useStore()

onMounted(() => {
  if (getToken()) {
    store.dispatch('user/initUserInfo')
  } else {
    router.replace(loginPath)
  }
})
</script>
